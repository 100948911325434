.container {
  position: absolute;
  top: 10px;
  left: 10%;
  z-index: 21;
  width: calc(80% - 10px);
  height: calc(100vh - 20px);
  background: hsla(239, 60%, 70%, 0.85);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  overflow: auto;
}
