.enter {
  animation: overlayEnter 0.3s ease-in-out forwards;
}

.exit {
  animation: overlayExit 0.3s ease-in-out forwards;
}

@keyframes overlayEnter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes overlayExit {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
