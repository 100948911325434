.blue {
  background-image: linear-gradient(
    hsl(239, 96%, 90%),
    hsl(239, 97%, 66%) 2%,
    hsl(239, 97%, 45%) 70%,
    hsl(239, 97%, 55%) 99%,
    hsl(239, 96%, 90%)
  );
  color: hsl(197, 100%, 93%);

  &:hover {
    background-image: linear-gradient(
      hsl(220, 96%, 90%),
      hsl(220, 97%, 66%) 2%,
      hsl(220, 97%, 45%) 70%,
      hsl(220, 97%, 55%) 99%,
      hsl(220, 96%, 90%)
    );
  }
}

.blue_active {
  background-image: linear-gradient(
    hsl(239, 96%, 90%),
    hsl(239, 97%, 86%) 2%,
    hsl(239, 97%, 65%) 70%,
    hsl(239, 97%, 75%) 99%,
    hsl(239, 96%, 90%)
  );
  color: hsl(197, 100%, 93%);
}

.green {
  background-image: linear-gradient(
    hsl(115, 96%, 90%),
    hsl(115, 97%, 40%) 2%,
    hsl(115, 96%, 30%) 70%,
    hsl(115, 97%, 35%) 99%,
    hsl(115, 96%, 90%)
  );
  color: hsl(92, 100%, 93%);
  &:hover {
    background-image: linear-gradient(
      hsl(115, 96%, 90%),
      hsl(115, 97%, 50%) 2%,
      hsl(115, 97%, 40%) 70%,
      hsl(115, 97%, 45%) 99%,
      hsl(115, 96%, 90%)
    );
  }
}
