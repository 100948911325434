.main {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 0;
  background: rgba(44, 178, 210, 0.9);
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  z-index: 10;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  text-align: center;
}

.block {
  display: inline-block;
  margin: 4px 20px;
  text-align: left;
}
