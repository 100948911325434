.default {
  --color: white;
  --hoverColor: whitesmoke;
  --inactiveColor: white;
  --textColor: #000000;
}

.black {
  --color: hsl(0, 0%, 15%);
  --hoverColor: hsl(0, 0%, 25%);
  --inactiveColor: hsl(0, 0%, 40%);
  --textColor: #FFFFFF;
}

.blue {
  --color: hsl(210, 100%, 33%);
  --hoverColor: hsl(210, 100%, 45%);
  --inactiveColor: hsl(210, 60%, 60%);
  --textColor: #FFFFFF;
}

.orange {
  --color: hsl(24, 100%, 50%);
  --hoverColor: hsl(24, 100%, 60%);
  --inactiveColor: hsl(24, 60%, 70%);
  --textColor: #FFFFFF;
}

.red {
  --color: hsl(0, 100%, 30%);
  --hoverColor: hsl(0, 100%, 40%);
  --inactiveColor: hsl(0, 60%, 60%);
  --textColor: #FFFFFF;
}

.pink {
  --color: hsl(315, 100%, 40%);
  --hoverColor: hsl(323, 100%, 60%);
  --inactiveColor: hsl(323, 60%, 70%);
  --textColor: #FFFFFF;
}

.green {
  --color: hsl(146, 70%, 25%);
  --hoverColor: hsl(150, 70%, 35%);
  --inactiveColor: hsl(150, 30%, 50%);
  --textColor: #FFFFFF;
}

.yellow {
  --color: hsl(50, 100%, 63%);
  --hoverColor: hsl(45, 100%, 80%);
  --inactiveColor: hsl(45, 60%, 80%);
  --textColor: #000000;
}

.purple {
  --color: hsl(300, 80%, 20%);
  --hoverColor: hsl(300, 80%, 35%);
  --inactiveColor: hsl(300, 40%, 60%);
  --textColor: #FFFFFF;
}
