.main {
  position: relative;
  display: grid;
  grid-template-rows: minmax(400px, 1fr) minmax(630px, 1fr);

  height: 100vh;
  max-height: 100vh;
  padding: 10px;
  overflow-y: hidden;
}

.menu {
  position: absolute;
  width: 54px;
  height: 54px;
  bottom: 25px;
  left: 10px;
}

.magnifier {
  position: absolute;
  width: 54px;
  height: 54px;
  bottom: 85px;
  left: 10px;
}

.secrets {
  position: absolute;
  top: 10px;
  left: 450px;
}

.pointCounter {
  position: absolute;
  top: 10px;
  left: 10px;

  width: 400px;
  padding-left: 34px;
  -webkit-user-select: none;
  user-select: none;
  align-self: end;
  transform: translateY(10px);
}

.objectives {
  position: absolute;
  bottom: 10px;
  left: 0;
}