.link {
  text-decoration: none;
}

.button {
  width: 100%;
  margin: 8px 0;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #3f3f3f;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}
