.main {
  margin: 0 20px;
  pointer-events: none;
}

.canvas {
  width: 60px;
}

.hex {
  /* filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.3)); */
  cursor: pointer;
  pointer-events: fill;
}