.main {
  width: calc(100vw - 20px);
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  -webkit-user-select: none;
  user-select: none;
}

.main a {
  text-decoration: none;
}

.colorPicker {
  width: 70%;
  margin: 0 auto;
}

.factions {
  --item-width: 175px;
  padding: 10px 30px 50px;
  gap: 2px;
  grid-row: span 2;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
}
