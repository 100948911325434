.main {
  display: flex;
  flex-direction: row;
}

.canvas {
  height: 62px;
  pointer-events: none;
  position: absolute;
  top: -6px;
  right: -70px;
}

.hex {
  stroke: whitesmoke;
  stroke-width: 10px;
  cursor: pointer;
  pointer-events: fill;
}

.active {
  fill: hsl(115, 97%, 35%);
  &:hover {
    fill: hsl(115, 97%, 45%);
  }
}

.inactive {
  fill: hsl(240, 33%, 38%);
  &:hover {
    fill: hsl(240, 33%, 48%);
  }
}

.icon {
  stroke: whitesmoke;
}