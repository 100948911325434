.main {
  --item-width: 200px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.image {
  width: var(--item-width);
}

.imageInactive {
  filter: drop-shadow(0 0 40px hsla(239, 60%, 60%, 0.2));
  z-index: 1;
}

.imageActive {
  filter: drop-shadow(0 0 20px white);
  cursor: pointer;
  z-index: 1;

  &:hover,
  &:has(+ .label:hover) {
    transform: scale(1.1);
    z-index: 10;
  }
}

.label {
  position: absolute;
  bottom: calc(0.05 * var(--item-width));
  left: calc(0.05 * var(--item-width));
  vertical-align: bottom;
  padding: 10px 5px;
  width: calc(0.9 * var(--item-width));
  margin: 0 auto;
  color: white;
  z-index: 20;
  text-transform: uppercase;
  line-height: 0.9;
  -webkit-user-select: none;
  user-select: none;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: hsla(239, 60%, 70%, 0.85);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border-radius: 2px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid hsla(239, 60%, 100%, 0.4);
    transform: skewY(-3deg);
    z-index: -1;
  }
}

.labelActive {
  cursor: pointer;
}
