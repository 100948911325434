.main {
  position: relative;
  display: inline-block;
  margin: 0 -25px;
  width: calc(var(--base-height) / 325 * 500);
  vertical-align: top;
  text-align: center;
  box-sizing: border-box;
  --_base-height: var(--base-height, 325px);
}

.canvas {
  position: relative;
}

.hex {
  stroke-width: 2px;
  cursor: pointer;
  pointer-events: fill;
}

.hex-stage1-active {
  fill: hsla(34, 86%, 20%, 0.7);
  stroke: hsl(43, 74%, 49%);
}

.hex-stage2-active {
  fill: hsla(240, 33%, 38%, 0.7);
  stroke: hsl(240, 100%, 40%);
}

.hex-secret-active {
  fill: hsla(0, 60%, 20%, 0.7);
  stroke: hsl(0, 80%, 40%);
}

.hex-stage1-inactive {
  fill: rgba(64, 64, 80, 0.70);

  &:hover {
    fill: rgba(80, 64, 20, 0.70);
  }
}

.hex-stage2-inactive {
  fill: rgba(64, 64, 80, 0.85);

  &:hover {
    fill: rgba(64, 64, 128, 0.85);
  }
}

.hex-beforeDelete {
  fill: rgb(160, 64, 64);
}

.magnified:hover {
  transform: scale(1.4) translateY(-15%);
  z-index: 200;
}

.magnified:hover + .content {
  transform: scale(1.4) translateY(-28%);
  z-index: 200;
}

.flower {
  cursor: pointer;
  stroke: #aaaaaa;
  stroke-width: 1px;
  filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.8));
  pointer-events: fill;
}

.activePetal {
  &:hover {
    fill: var(--hoverColor);
  }
}

.checkedPetal {
  fill: var(--color);
}

.uncheckedPetal {
  fill: color-mix(in srgb, var(--color) 20%, transparent);
}

.plus-stage1 {
  fill: goldenrod;
}

.plus-stage2 {
  fill: #6060f0;
}

.content {
  position: absolute;
  top: calc(var(--_base-height) / 221 * 80);
  left: 10%;
  margin: 0;
  padding: calc(var(--_base-height) / 221 * 4)
    calc(var(--_base-height) / 221 * 8);
  width: 80%;
  pointer-events: none;
  --content-base-height: var(--_base-height);
  color: white;
}
