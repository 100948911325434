.main {
  position: relative;
  margin: 100px auto;
}

.grid {
  --image-width: 175px;
  --image-height: calc(0.5 * sqrt(3) * var(--image-width));
  --gap: 20px;

  transform: translateX(calc(-0.125 * var(--image-width)));
  display: grid;
  grid-template-columns: repeat(3, calc(0.75 * var(--image-width)));
  grid-template-rows: repeat(6, max(0.5 * var(--image-height)));
  gap: var(--gap) calc(sqrt(3) * var(--gap));
  align-items: unsafe end;
}

.container {
  position: relative;
  margin: 0;
  padding: 0;
  width: var(--image-width);
  grid-row: span 2;
  cursor: pointer;

  &:nth-child(1) {
    grid-column: 2;
    z-index: 1;
  }
  
  &:nth-child(2) {
    grid-column: 3;
    grid-row: 2 / span 2;
    z-index: 2;
  }
  
  &:nth-child(3) {
    grid-column: 3;
    z-index: 3;
  }
  
  &:nth-child(4) {
    grid-column: 2;
    z-index: 4;
  }
  
  &:nth-child(5) {
    grid-row: 4 / span 2;
    grid-column: 1;
    z-index: 3;
  }
  
  &:nth-child(6) {
    grid-row: 2 / span 2;
    grid-column: 1;
    z-index: 2;
  }
}

.image {
  width: 100%;
  margin: 0;
  padding: 0;

  &:hover {
    transform: scale(1.1);
  }
}

.active {
  filter: drop-shadow(0 0 10px rgb(255, 0, 0));
}

.canvas {
  position: absolute;
  width: 80px;
  bottom: -28px;
  right: 50%;
  transform: translateX(50%);
  pointer-events: none;
}

.colorActive {
  filter: drop-shadow(0 0 10px red);
}

.coloredCanvas {
  position: absolute;
  top: -170px;
  left: 0;
  width: 100%;
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.9));
  pointer-events: none;
}

.hex,
.colorHexes {
  pointer-events: fill;
  cursor: pointer;
}

.hex {
  fill: var(--color);
  &:hover {
    fill: var(--hoverColor);
  }
}