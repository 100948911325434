.total {
  width: 100vw;
  max-width: 100%;
  padding: 2rem 1rem;
}

.main {
  max-width: 500px;
  margin: 0 auto;
  padding: 32px;
  padding-top: 8px;
  text-align: center;
  background-color: hsl(300, 76%, 93%);
  border: 1px solid blueviolet;
  border-radius: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  font-size: 1.1rem;
}
