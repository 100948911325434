.main {
  position: relative;
  display: inline-block;
  margin: 0 1px;
  width: 266px;
  vertical-align: top;
  text-align: center;
  box-sizing: border-box;
}

.pointer {
  cursor: pointer;
}

.content {
  pointer-events: none;
  position: absolute;
  top: 0;
  margin: 0;
  padding: 4px 8px;
  --content-base-height: 216px;
}

.hex {
  stroke-width: 5px;
}

.stage1-fill {
  fill: hsl(34, 86%, 20%);
}

.stage2-fill {
  fill: hsl(240, 33%, 38%);
}

.secret-fill {
  fill: hsl(0, 60%, 20%);
}

.stage1-stroke {
  stroke: hsl(43, 74%, 49%);
}

.stage2-stroke {
  stroke: hsl(240, 100%, 40%);
}

.secret-stroke {
  stroke: hsl(0, 80%, 40%);
}

.colorFill {
  fill: var(--color);
}

.defaultContent {
  color: white;
}

.coloredContent {
  color: var(--textColor, white);
}
