.grid {
  --image-width: 120px;
  --image-height: calc(0.5 * sqrt(3) * var(--image-width));
  --gap: 18px;

  isolation: isolate;
  transform: translateX(calc(-0.125 * var(--image-width)));
  display: grid;
  grid-template-columns: repeat(3, calc(0.75 * var(--image-width)));
  grid-template-rows: repeat(6, max(0.5 * var(--image-height)));
  gap: var(--gap) calc(sqrt(3) * var(--gap));
  align-items: unsafe end;
}

.container {
  position: relative;
  margin: 0;
  padding: 0;
  width: var(--image-width);
  grid-row: span 2;

  &:nth-child(1) {
    grid-column: 2;
    z-index: 1;
  }
  
  &:nth-child(2) {
    grid-column: 3;
    grid-row: 2 / span 2;
    z-index: 2;
  }
  
  &:nth-child(3) {
    grid-column: 3;
    z-index: 3;
  }
  
  &:nth-child(4) {
    grid-column: 2;
    z-index: 4;
  }
  
  &:nth-child(5) {
    grid-row: 4 / span 2;
    grid-column: 1;
    z-index: 3;
  }
  
  &:nth-child(6) {
    grid-row: 2 / span 2;
    grid-column: 1;
    z-index: 2;
  }
}

.image {
  width: 100%;
  margin: 0;
  padding: 0;
  filter: drop-shadow(0 0 10px hsla(239, 60%, 60%, 0.8));
}

.canvas {
  position: absolute;
  width: 85px;
  bottom: -28px;
  right: 50%;
  transform: translateX(50%);
  pointer-events: none;
}

.points {
  position: absolute;
  bottom: -6px;
  right: 50%;
  font-size: 2.8rem;
  letter-spacing: -4px;
  transform: translateX(calc(50% - 2px));
}

.clock {
  grid-row: 3 / span 2;
  grid-column: 2;
  transform: translateY(-40%);
  width: var(--image-width);
  font-size: 2.8rem;
  color: yellow;
}